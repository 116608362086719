import type {
  TeacherListView,
  TeacherPageView,
} from "~/business-areas/teacher/teacher.model";

/*
In memory cache for instant loading from teacher card list to teacher page

Same implementation as course-cache-list.hook.ts, check it for more info.
 */

export function useTeacherCache() {
  const cache = useState(
    "teacher_cache",
    () => new Map<string, TeacherPageView>(),
  );

  function getBySlug(slug: string) {
    return cache.value.get(slug);
  }

  function setMultiple(teachers: TeacherListView[]) {
    for (const teacher of teachers) {
      cache.value.set(teacher.slug!, {
        ...teacher,
        courses: teacher.courses.map((course) => {
          return {
            ...course,
            sessions: [],
          };
        }),
      });
    }
  }

  return {
    getBySlug,
    setMultiple,
  };
}
