import type { TeacherListView } from "~/business-areas/teacher/teacher.model";

export type TeacherFilters = {
  tags?: string[];
  limit?: number;
  pinned?: boolean;
  exclude?: string[];
  sortOrder?: "ASC" | "DESC";
  sortField?: string;
  slugs?: string[];
};

export function useTeacherList(
  key: string,
  filters?: TeacherFilters,
  options?: {
    lazy?: boolean;
    server?: boolean;
    transform?: (teachers: TeacherListView[]) => TeacherListView[];
    dedupe?: "cancel" | "defer";
  },
) {
  const cache = useTeacherCache();

  return useFetch("/api/teachers", {
    query: filters,
    watch: filters ? Object.values(toRefs(filters)) : undefined,
    server: options?.server,
    lazy: options?.lazy,
    default: () => [],
    dedupe: options?.dedupe,
    transform(data) {
      if (!data) return [];

      const views: TeacherListView[] = data.map((teacher) => {
        return {
          ...teacher,
          mainTag: teacher.tags[0],
          courses: teacher.courses.map((course) => {
            const ratings = course.ratings[0];

            return {
              ...course,
              ratings:
                ratings.avg !== null && ratings.count > 0
                  ? {
                      avg: ratings.avg,
                      count: ratings.count,
                    }
                  : null,
            };
          }),
          _links: {
            teacherPage: {
              name: "trainers-slug",
              params: {
                slug: teacher.slug!,
              },
            },
          },
        };
      });

      cache.setMultiple(views);

      if (options?.transform) {
        return options.transform(views);
      }

      return views;
    },
  });
}
